import React, { useState } from "react"
import { ResultModalContext } from "../../../context/ResultModal"
import DestinationSearchInput from "./components/DestinationSearchInput/DestinationSearchInput"
import RangePicker from "./components/DatePickerModal/RangePicker"
import Loader from "react-loader-spinner"
import { getHotelResults } from "../../../api"
import SearchIcon from "../../.../../../images/svg/search.svg"
import ArrowIcon from "../../.../../../images/svg/SearchArrow.svg"
import "./DestinationSearchBar.css"

const DestinationSearchBar = ({ modal, isBgBlack, setDestinations, isResultsLoading, setIsResultsLoading }) => {
  const [myDates, setMyDates] = useState(null)
  const [searchedValueError, setSearchedValueError] = useState({
    location: true,
    adults: true,
    rooms: true,
    checkInDate: true,
    checkOutDate: true,
    clicked: false,
  })

  const searchHotels = async (searchParameters, setIsResultModalOpen) => {
    const isValid = checkSearchInputsValidation(searchParameters)

    if (isValid) {
      setIsResultsLoading(true)
      try {
        const res = await getHotelResults({
          location: searchParameters.location,
          checkInDate: searchParameters.checkInDate,
          checkOutDate: searchParameters.checkOutDate,
          adultVisitors: searchParameters.guestsDetails.adults,
          childrenVisitors: [],
          noOfRooms: searchParameters.guestsDetails.rooms,
          init: true
        })

        const destinationsData = res.data.map(destination => {
          return {
            ...destination,
            description: destination.info?.description,
            price: destination.data?.price,
            image: {
              url: `${process.env.REACT_APP_SETUR_CDN}/image/hotel/large/${destination.info?.imageUrl}`
            }
          }
        })

        setDestinations(destinationsData)
      } catch (error) {
        console.log(error)
      }
      setIsResultsLoading(false)
      setIsResultModalOpen(true)
    }
  }
  const checkSearchInputsValidation = (searchedItems) => {
    setSearchedValueError((prevVal) => {
      return {
        ...prevVal,
        clicked: true,
        location: !searchedItems.location,
        checkInDate: !searchedItems.checkInDate,
        checkOutDate: !searchedItems.checkOutDate,
        adults: !searchedItems.guestsDetails.adults,
        rooms: !searchedItems.guestsDetails.rooms,
      }
    })

    return !!searchedItems.location &&
      !!searchedItems.checkInDate &&
      !!searchedItems.checkOutDate &&
      !!searchedItems.guestsDetails.adults &&
      !!searchedItems.guestsDetails.rooms
  }

  return (
    <div>
      <div
        style={{ background: isBgBlack ? "#181818" : "transparent" }}
        className={`destination-search-bar-root ${modal ? "modal" : ""}`}
      >
        <div className="search-bar-left-side">
          {modal ? (
            <>
              <ResultModalContext.Consumer>
                {({ searchParameters }) => (
                  <DestinationSearchInput
                    label="location"
                    placeHolder="Where are you going?"
                    isLocation
                    searchParameter={searchParameters.location}
                  />
                )}
              </ResultModalContext.Consumer>
            </>
          ) : (
            <>
              <DestinationSearchInput
                isEmpty={{
                  clicked: searchedValueError.clicked,
                  status: searchedValueError.location,
                }}
                label="location"
                placeHolder="Where are you going?"
                isLocation
              />
            </>
          )}
        </div>
        <div className="search-bar-right-side">
          <ResultModalContext.Consumer>
            {({ searchParameters, setSearchParameters }) => (
              <RangePicker
                searchedValueError={searchedValueError}
                searchParameters={searchParameters}
                setSearchParameters={setSearchParameters}
                myDates={myDates}
                setMyDates={setMyDates}
              />
            )}
          </ResultModalContext.Consumer>

          <DestinationSearchInput
            label="Guests"
            placeHolder="Add guests"
            isAddGuest
            isEmpty={{
              clicked: searchedValueError.clicked,
              status: searchedValueError.adults || searchedValueError.rooms,
            }}
          />
          <ResultModalContext.Consumer>
            {({ setIsResultModalOpen, searchParameters }) => (
              isResultsLoading ? (
                <div className="tailspin-loader-container">
                  <Loader type="TailSpin" color="#00BFFF" height={30} width={30} />
                </div>
              ) : (
                <img
                  className="search-icon"
                  src={modal ? ArrowIcon : SearchIcon}
                  alt="search icon"
                  onClick={() => searchHotels(searchParameters, setIsResultModalOpen)}
                />
              )
            )}
          </ResultModalContext.Consumer>
        </div>
      </div>
    </div>
  )
}

export default DestinationSearchBar
