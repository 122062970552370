import React from "react"
import Typography from "../../../Typography/Typography"
import { useTranslation } from "react-i18next"
import "./PackageCard.css"

const PackageCard = ({
  imageURL,
  title,
  price,
  duration_day,
  duration_night,
  description,
}) => {
  const { t } = useTranslation("packages")
  return (
    <div className="PackageCard">
      <img src={imageURL} alt={title} className="thumb" />
      <Typography title text={title} category="title" />
      <div className="description-container">
        <Typography text={description} category="description" />
      </div>
      <div className="info-container">
        <div className="duration-container">
          <Typography category="label" text={t("packages.duration")} />
          <Typography category="info" text={`${duration_day}D/${duration_night}N`} />
        </div>
        <div className="price-container">
          <Typography category="label" text={t("packages.startingprice")} />
          <Typography category="info" text={`₺${price}`} />
        </div>
      </div>
    </div>
  )
}

export default PackageCard
