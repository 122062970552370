import React, { useEffect, useState } from "react";
import Home from "./components/pages/Home";
import Hotels from "./components/pages/Hotels";
import {
  Routes,
  Route,
} from "react-router-dom";
import "./App.css";
import 'antd/dist/antd.css';

function App() {
  const [isResultModalOpen, setIsResultModalOpen] = useState(false)

  useEffect(() => {
    if (!localStorage.getItem("language")) {
      localStorage.setItem("language", "tr")
    }
  }, [])
  return (
    <div className={`App ${isResultModalOpen ? "open-modal" : ""}`}>
      <Routes>
        <Route exact path="/hotels/:id/:slug" element={<Hotels />} />
        <Route exact path="/" element={
          <Home
            isResultModalOpen={isResultModalOpen}
            setIsResultModalOpen={setIsResultModalOpen}
          />
        } />
      </Routes>
    </div>
  )
}

export default App
