import DayToursCard from "../elements/DayTourSection/DayToursCard"
import Typography from "../elements/Typography/Typography"
import DownArrow from "../../images/downArrow.svg"
import { useTranslation } from "react-i18next"
import "../elements/DayTourSection/DayTour.css"
import "../../App.css"
import { useState } from "react"

const DayTours = () => {
  const { t } = useTranslation("daytours");
  const [ isExpand, setIsExpand ] = useState(false)
    const [showMore, setShowMore] = useState(false)

  const expandTiles = () => {
      setIsExpand(!isExpand)
      setShowMore(!showMore)
  }

  return (
    <div className="day-tour-section section-padding" id="daytours">
      <Typography title text={t("daytours.title")} category="day-tour-header" />
      <DayToursCard expand={isExpand}/>
      <div className="view-more-container" onClick={expandTiles}>
        <Typography category="show-more-text" text = {showMore ? t("daytours.showLess") : t("daytours.showmore")} />
        <img  className={`show-more-button ${showMore ? "rotate-arrow" : ''}`} src={DownArrow} alt="down-arrow-day-tours" />
      </div>
    </div>
  )
}

export default DayTours
