import React from "react"
import moment from "moment"
import { DatePicker } from "antd"
import RangePickerFooter from "./RangePickerFooter"

const RangePicker = ({
  searchedValueError,
  searchParameters,
  setSearchParameters,
  myDates,
  setMyDates
}) => {
  const { RangePicker: AntdRangePicker } = DatePicker

  return (
    <AntdRangePicker
      allowClear={false}
      suffixIcon={null}
      separator={null}
      bordered={false}
      placeholder={["Add dates", "Add dates"]}
      className={`rangepickerinput ${
        searchedValueError.clicked && (searchedValueError.checkInDate || searchedValueError.checkDate)
          ? "show-date-err"
          : ""
      }`}
      dropdownClassName={"rangepickerdropdown"}
      renderExtraFooter={() => (
        <RangePickerFooter mydates={myDates} setMyDates={setMyDates} />
      )}
      defaultValue={
        searchParameters.checkInDate ?
          [moment(searchParameters.checkInDate, "YYYY-MM-DD"), moment(searchParameters.checkOutDate, "YYYY-MM-DD")]
        : []
      }
      onCalendarChange={(range, date) => {
        const checkInDate = moment(date[0], "Do MMM YYYY").format("YYYY-MM-DD");
        const checkOutDate = moment(date[1], "Do MMM YYYY").format("YYYY-MM-DD");
        setSearchParameters({ ...searchParameters, checkInDate, checkOutDate })
      }}
      format="Do MMM"
    />
  )
}

export default RangePicker