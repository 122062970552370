import React from "react"
import "./GuestIncrement.css"
const GuestIncrement = ({
  guestName,
  guestNameExplained,
  guestNumber,
  handleGuestOperation,
}) => {
  return (
    <div className="guest-increment-root">
      <div className="guest-increment-name-container">
        <p className="guest-name">{guestName}</p>
        {guestNameExplained && <p className="guest-subname">{guestNameExplained}</p>}
      </div>
      <div className="guest-buttons">
        <div
          className={`guest-sub-inc-button ${
            guestNumber < 1 && "disabled-guest-button"
          }`}
          onClick={() => handleGuestOperation("sub", guestName)}
        >
          -
        </div>
        <div className="guest-total-number">{guestNumber}</div>
        <div
          className={`guest-sub-inc-button ${
            guestName === "Infants"
              ? guestNumber > 9 && "disabled-guest-button"
              : guestNumber > 29 && "disabled-guest-button"
          }`}
          onClick={() => handleGuestOperation("add", guestName)}
        >
          +
        </div>
      </div>
    </div>
  )
}

export default GuestIncrement
