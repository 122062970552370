
import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import heroTR from "./locales/tr/hero.json"
import heroEN from "./locales/en/hero.json"
import commonTR from "./locales/tr/common.json"
import commonEN from "./locales/en/common.json"
import destinationsEN from "./locales/en/destinations.json"
import destinationsTR from "./locales/tr/destinations.json"
import packagesTR from "./locales/tr/packages.json"
import packagesEN from "./locales/en/packages.json"
import daytoursTR from "./locales/tr/daytours.json"
import daytoursEN from "./locales/en/daytour.json"
import servicesTR from "./locales/tr/services.json"
import servicesEN from "./locales/en/services.json"
import footerTR from "./locales/tr/footer.json"
import footerEN from "./locales/en/footer.json"
import faqTR from "./locales/tr/faq.json"
import faqEN from "./locales/en/faq.json"
import commonRU from "./locales/ru/common.json"
import daytoursRU from "./locales/ru/daytours.json"
import destinationsRU from "./locales/ru/destinations.json"
import faqRU from "./locales/ru/faq.json"
import footerRU from "./locales/ru/footer.json"
import heroRU from "./locales/ru/hero.json"
import packagesRU from "./locales/ru/packages.json"
import servicesRU from "./locales/ru/services.json"
const resources = {
  tr: {
    common: commonTR,
    hero: heroTR,
    destinations: destinationsTR,
    packages: packagesTR,
    daytours: daytoursTR,
    services: servicesTR,
    footer: footerTR,
    faq: faqTR,
  },
  en: {
    common: commonEN,
    hero: heroEN,
    destinations: destinationsEN,
    packages: packagesEN,
    daytours: daytoursEN,
    services: servicesEN,
    footer: footerEN,
    faq: faqEN,
  },
  ru: {
    common: commonRU,
    hero: heroRU,
    packages: packagesRU,
    daytours: daytoursRU,
    services: servicesRU,
    footer: footerRU,
    faq: faqRU,
    destinations: destinationsRU,
  },
}
const lng = localStorage.getItem("language") || "tr"
i18n.use(initReactI18next).init({
  resources,
  lng,
  fallbackLng: "en",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
})
export default i18n
