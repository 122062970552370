import React from "react"
import PackagesSectionImage from "../components/PackagesSectionImage/PackagesSectionImage"
import PackagesCarousel from "../components/PackagesCarousel/PackagesCarousel"
import "./PackagesSection.css"

const PackagesSection = () => {
  return (
    <div className="PackagesSection">
      <PackagesSectionImage />
      <PackagesCarousel />
    </div>
  )
}

export default PackagesSection
