import Logo from "../../../images/logo.svg"

const HeaderLogo = () => {
  return (
    <div
      onClick={() => window.scrollTo({ top: 0, left: 0, behavior: "smooth" })}
      className="header-logo"
    >
      <img style={{ width: "100%", height: "100%" }} src={Logo} alt="logo" />
    </div>
  )
}

export default HeaderLogo
