import React, { useRef, useEffect, useState } from "react"
import mapboxgl from "mapbox-gl"
import "./Mapbox.css"

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN

const Mapbox = ({ markers = [], isDedicated }) => {
  const mapContainerRef = useRef(null)
  const moringaCoords = {
    longitude: 30.517337721264727,
    latitude: 39.77855682022669,
  }

  const defaultMarker = markers.length ? markers[0] : moringaCoords

  const [mapInstance, setMap] = useState(null)

  // Initialize map when component mounts
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: process.env.REACT_APP_MAPBOX_STYLE,
      center: [defaultMarker.longitude, defaultMarker.latitude],
      zoom: 12.4,
      attributionControl: false,
    })

    setMap(map)

    new mapboxgl.Marker()
      .setLngLat([defaultMarker.longitude, defaultMarker.latitude])
      .addTo(map);

    // Clean up on unmount
    return () => map.remove()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isDedicated) {
      markers.forEach((marker, i) => {
        if (marker.longitude && marker.latitude) {
          let element = null

          if (marker.price) {
            element = document.createElement('div')
            element.className = "map-marker"
            element.innerHTML =  `<p class="map-marker-text">₺${marker.price}</p>`
          }

          new mapboxgl.Marker(element)
            .setLngLat([marker.longitude, marker.latitude])
            .addTo(mapInstance);

          if (i === 0) {
            mapInstance.setCenter([marker.longitude, marker.latitude])
          }
        }
      })
    }
  }, [markers, isDedicated, mapInstance])

  return (
    <div>
      <div className="map-container" ref={mapContainerRef} />
    </div>
  )
}

export default Mapbox
