import React, { useEffect, useState } from "react"
import Typography from "../Typography/Typography"
import axios from "axios"

const DayToursCard = (prop) => {
  const [dayTours, SetDayTours] = useState([])

  useEffect(() => {
    const getDayTours = async () => {
      try {
        const response = await axios.get(
          "https://cms.test.archisacademy.com/hospitality-day-tours?_sort=name"
        )
        SetDayTours(response.data)
      } catch (error) {
        console.log(error.message)
      }
    }
    getDayTours()
  }, [])

  return (
    <div className={prop.expand ? "day-tour-content-section day-tour-content-section-expand" : "day-tour-content-section"} >
      {dayTours.length !== 0 &&
        dayTours.map((dayTour) => {
          return (
            <div key={dayTour.id} className={prop.expand ? "day-tour-card day-tour-card-expand" : "day-tour-card"}>
              <div className={prop.expand ? "day-tour-card-image day-tour-card-image-expand" : "day-tour-card-image"}>
                <img
                  className="content-img"
                  src={dayTour.image.url}
                  alt={dayTour.name}
                />
              </div>
              <div className="day-tour-card-name">
                <Typography text={dayTour.name} category="content-head" />
                <Typography text={dayTour.location} category="content-place" />
              </div>
            </div>
          )
        })}
      ;
    </div>
  )
}

export default DayToursCard
