import React from "react";
import ServicesSection from "../elements/ServicesSection/ServicesSection";

const Services = () => {
  return (
    <>
      <ServicesSection />
    </>
  );
}

export default Services;