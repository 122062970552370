import React from "react"
import "./PackagesSectionImage.css"
import ReactPlayer from 'react-player'

const PackagesSectionImage = () => {
  return (
    <div className="PackagesSectionImage">
      <ReactPlayer playing loop height="1080px" width="1920px" url="https://vimeo.com/568067637/593b8baf17" />
    </div>
  )
}

export default PackagesSectionImage
