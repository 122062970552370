import React, { useState } from "react";
import Typography from "../../Typography/Typography"

import "./DestinationsCard.css";

const DestinationsCard = ({
    imageURL,
    title,
    description,
    defaultCard,
    setDefaultCard,
    showMore
}) => {
    const [active, setActive ] = useState(false)

    const handleOnMouseEnter = () => {
        setActive(true)
        setDefaultCard(title)
    }

    const handleOnMouseLeave = () => {
        setActive(false)
    }

    return (
        <div className={`destination-card ${active || defaultCard === title ? "active" : ""}`}
            onMouseEnter = {handleOnMouseEnter}
            onMouseLeave = {handleOnMouseLeave}
            style = {{
                marginRight: showMore ? "20px" : "100px"
            }}
        >
            <div className="destination-card-image">
                <img src={imageURL} alt={title} className="thumb" />
            </div>
            <Typography title category="title" text={title} />
            <div className="description-container">
            <Typography category="description" text={description}/>
                {description}
            </div>
        </div>

    )
}

export default DestinationsCard; 