import React from "react"
import HeaderLogo from "../elements/HeaderSection/HeaderLogo"
import HeaderLinks from "../elements/HeaderSection/HeaderLinks"
import "../elements/HeaderSection/Header.css"

const Header = ({ isSearchHidden = false }) => {
  return (
    <div style={{ zIndex: 10000 }} className="header">
      <HeaderLogo />
      <HeaderLinks isSearchHidden={isSearchHidden} />
    </div>
  )
}

export default Header
