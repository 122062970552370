import React from "react"
import { Row, Col } from "antd"
import ServiceCard from "./ServiceCard"
import "./ServicesSection.css"
import FaqSection from "./FaqSection/FaqSection"
import Typography from "../Typography/Typography"
import { useTranslation } from "react-i18next"

const ServicesSection = () => {
  const { t } = useTranslation("services")
  const services = [
    {
      id: 1,
      title: t("services.title.Accomodation"),
      imageURL: "./assets/services/img1.png",
    },
    {
      id: 2,
      title: t("services.title.Transportation"),
      imageURL: "./assets/services/img2.png",
    },
    {
      id: 3,
      title: t("services.title.Golf"),
      imageURL: "./assets/services/img3.png",
    },
    {
      id: 4,
      title: t("services.title.Medical"),
      imageURL: "./assets/services/img4.png",
    },
    {
      id: 5,
      title: t("services.title.Wellness"),
      imageURL: "./assets/services/img5.png",
    },
    {
      id: 6,
      title: t("services.title.TailorMade"),
      imageURL: "./assets/services/img6.png",
    },
  ]
  return (
    <div className="services" id="services">
      <Typography title category="services-title" text={t("services.title")} />
      <Row>
        <Col xs={24} xl={10}>
          <Typography
            category="services-description-1"
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sapien morbi sed feugiat diam est. Felis proin turpis morbi elit, lectus vel massa mauris.
          Non at et pretium viverra a posuere."
          />
        </Col>
        <Col className="services-img-container" span={14}>
          {services.map((service) => (
            <ServiceCard
              key={service.id}
              title={service.title}
              url={service.imageURL}
            />
          ))}
        </Col>
        <Col xs={24} xl={10}>
          <Typography category="services-description-2" text={t("services.info")} />
        </Col>
        <FaqSection />
      </Row>
    </div>
  )
}

export default ServicesSection
