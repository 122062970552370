import React, { useState, useEffect } from "react"
import { MenuOutlined, CaretDownOutlined } from "@ant-design/icons"
import Typography from "../Typography/Typography"
import CancelIcon from "../../.../../../images/svg/CancelSearch.svg"
import { ResultModalContext } from "../../../context/ResultModal"
import Search from "../../../images/svg/search.svg"
import DestinationSearchBar from "../DestinationSearchTopSection/DestinationSearchBar"
import { useTranslation } from "react-i18next"
import i18n from "../../../i18n"

const HeaderLinks = ({ isSearchHidden }) => {
  const [isHeaderMobileMenuVisible, setMenuVisible] = useState(false)
  const [isLangMenuVisible, setLangMenuVisible] = useState(false)
  const [showSearchResult, setShowSearchResult] = useState(false)
  const [currentScroll, setCurrentScroll] = useState(null)
  const [scrollPosition, setScrollPosition] = useState(0)
  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true })

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])
  const handleHeaderMenuClick = () => {
    setMenuVisible(!isHeaderMobileMenuVisible)
  }
  const { t } = useTranslation("common")
  const [language, setLanguage] = useState(localStorage.getItem("language"))

  const handleLangMenuClick = () => {
    setLangMenuVisible(!isLangMenuVisible)
  }
  const changeLanguage = (lng) => {
    localStorage.setItem("language", lng)
    i18n.changeLanguage(lng)
    setLanguage(lng)
    setLangMenuVisible(!isLangMenuVisible)
  }
  const handleCloseSearchResults = (setIsResultModalOpen, setSearchParameters) => {
    setIsResultModalOpen(false)
    setSearchParameters({
      location: "",
      checkInDate: "",
      checkOutDate: "",
      guests: 0,
      guestsDetails: { adults: 0, children: 0, rooms: 0 },
    })
  }

  return (
    <header>
      <nav className="header-links">
        <Typography
          url={"#destinations"}
          category="header-link-each"
          text={t("header.destinations")}
        />
        <Typography
          url={"#packages"}
          category="header-link-each"
          text={t("header.packages")}
        />
        <Typography
          url={"#daytours"}
          category="header-link-each"
          text={t("header.daytours")}
        />
        <Typography
          url={"#services"}
          category="header-link-each"
          text={t("header.services")}
          className="header-link-last"
        />

        <div className="header-langmenu-container">
          <div onClick={handleLangMenuClick} className="header-langmenu-button">
            <p className="header-lanugage-link">{language}</p>
            <span className="custom-arrow">
              <CaretDownOutlined />
            </span>
          </div>

          {isLangMenuVisible ? (
            <div className="header-langmenu-dropdown">
              <p onClick={(e) => changeLanguage("tr")} className="header-link-each">
                Türkçe
              </p>
              <p onClick={(e) => changeLanguage("en")} className="header-link-each">
                English
              </p>
              <p onClick={(e) => changeLanguage("ru")} className="header-link-each">
                Pусский
              </p>
            </div>
          ) : null}
        </div>

        <ResultModalContext.Consumer>
          {({ isResultModalOpen, setIsResultModalOpen, setSearchParameters }) =>
            isResultModalOpen && (
              <img
                src={CancelIcon}
                className="search-result-close-icon"
                alt="search icon"
                onClick={() =>
                  handleCloseSearchResults(setIsResultModalOpen, setSearchParameters)
                }
              />
            )
          }
        </ResultModalContext.Consumer>
        <div className="header-link-menu">
          <select
            className="header-langmenu"
            value={language}
            name="lang"
            id="lang"
            onChange={(e) => {
              changeLanguage(e.target.value)
            }}
          >
            <option value="en">English</option>
            <option value="tr">Turkish</option>
          </select>
          <MenuOutlined onClick={handleHeaderMenuClick} />
        </div>
        <ResultModalContext.Consumer>
          {({ isResultModalOpen }) =>
            !isSearchHidden && !isResultModalOpen &&
            scrollPosition > 71 &&
            scrollPosition !== currentScroll && (
              <img
                src={Search}
                onClick={() => {
                  setShowSearchResult(true)
                  setCurrentScroll(scrollPosition)
                }}
                style={{ marginRight: 20, cursor: "pointer" }}
                alt="search"
              />
            )
          }
        </ResultModalContext.Consumer>
      </nav>
      {scrollPosition === currentScroll && showSearchResult && (
        <div style={{ zIndex: 10, position: "absolute", top: 0, left: "10%" }}>
          <ResultModalContext.Consumer>
            {({ setDestinations }) =>
              <DestinationSearchBar setDestinations={setDestinations} isBgBlack />
            }
          </ResultModalContext.Consumer>
        </div>
      )}
      {isHeaderMobileMenuVisible ? (
        <nav className="header-links-mobile">
          <Typography
            url={"#destinations"}
            category="header-link-each"
            text={t("header.destinations")}
          />
          <Typography
            url={"#packages"}
            category="header-link-each"
            text={t("header.packages")}
          />
          <Typography
            url={"#daytours"}
            category="header-link-each"
            text={t("header.daytours")}
          />
          <Typography
            url={"#services"}
            category="header-link-each"
            text={t("header.services")}
          />
        </nav>
      ) : null}
    </header>
  )
}

export default HeaderLinks
