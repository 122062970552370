import React, { useState } from "react"
import Loader from "react-loader-spinner"
import AddGuestModal from "./components/AddGuestModal/AddGuestModal"
import RangePicker from "./components/DatePickerModal/RangePicker"
import SearchIcon from "../../.../../../images/svg/search.svg"
import { getRoomPrice } from "../../../api"
import "./RoomSearchBar.css"

const RoomSearchBar = ({
  id,
  checkInDate,
  checkOutDate,
  adultVisitors,
  rooms
}) => {
  const [myDates, setMyDates] = useState(null)
  const [searchedValueError, setSearchedValueError] = useState({
    adults: true,
    rooms: true,
    checkInDate: true,
    checkOutDate: true,
    clicked: false,
  })
  const [isResultsLoading, setIsResultsLoading] = useState(false)
  const [isInputFocusing, setIsInputFocusing] = useState(false)
  const [searchParameters, setSearchParameters] = useState({
    id: +id,
    checkInDate: checkInDate,
    checkOutDate: checkOutDate,
    guests: +adultVisitors,
    guestsDetails: {
      adults: +adultVisitors,
      children: 0,
      rooms: +rooms,
    },
    init: false,
  })

  const searchRooms = async (params) => {
    const isValid = checkSearchInputsValidation(params)

    if (isValid) {
      setIsResultsLoading(true)
      try {
        const res = await getRoomPrice({
          id: params.id,
          checkInDate: params.checkInDate,
          checkOutDate: params.checkOutDate,
          adultVisitors: params.guestsDetails.adults,
          childrenVisitors: [],
          noOfRooms: params.guestsDetails.rooms,
          init: true
        })

        console.log(res)
      } catch (error) {
        console.log(error)
      }
      setIsResultsLoading(false)
    }
  }

  const checkSearchInputsValidation = (searchedItems) => {
    setSearchedValueError((prevVal) => {
      return {
        ...prevVal,
        clicked: true,
        checkInDate: !searchedItems.checkInDate,
        checkOutDate: !searchedItems.checkOutDate,
        adults: !searchedItems.guestsDetails.adults,
        rooms: !searchedItems.guestsDetails.rooms,
      }
    })

    return !!searchedItems.checkInDate &&
      !!searchedItems.checkOutDate &&
      !!searchedItems.guestsDetails.adults &&
      !!searchedItems.guestsDetails.rooms
  }

  return (
    <div className="rooms-search-bar-root" >
      <RangePicker
        searchedValueError={searchedValueError}
        searchParameters={searchParameters}
        setSearchParameters={setSearchParameters}
        myDates={myDates}
        setMyDates={setMyDates}
      />

      <div className="guests-container">
        <input
          style={{
            borderBottom:
            searchedValueError && searchedValueError.clicked && (searchedValueError.adults || searchedValueError.rooms)
                ? "2px solid red"
                : "none",
          }}
          readOnly="readonly"
          value={`${searchParameters.guests} guests / ${searchParameters.guestsDetails.rooms} rooms`}
          onChange={({ target }) => setSearchParameters({ ...searchParameters, guests: target.value })}
          placeholder="Add guests"
          onFocus={() => setIsInputFocusing(true)}
          onBlur={() => setTimeout(() => setIsInputFocusing(false), 100)}
          className="rooms-search-input"
        />
        {isInputFocusing && (
          <AddGuestModal
            setSearchValue={setSearchParameters}
            searchParameters={searchParameters}
          />
        )}
        {isResultsLoading ? (
          <div className="tailspin-loader-container">
            <Loader type="TailSpin" color="#00BFFF" height={30} width={30} />
          </div>
        ) : (
          <img
            className="search-icon"
            src={SearchIcon}
            alt="search icon"
            onClick={() => searchRooms(searchParameters)}
          />
        )}
      </div>
    </div>
  )
}

export default RoomSearchBar
