import React from "react"
import DestinationsCarousel from "../DestinationSection/components/DestinationsCarousel"

import "./DestinationSection.css"


const DestinationSection = () => {
    return (
        <div className="DestinationSection" id="destinations">
            <DestinationsCarousel />
        </div>
    )
}

export default DestinationSection
