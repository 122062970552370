import React, { useState } from "react"
import { ResultModalContext } from "../../../../../context/ResultModal"

import Modal from "../../../../Modal/Modal"
import SearchInputModal from "../SearchInputModal/SearchInputModal"
import AddGuestModal from "../AddGuestModal/AddGuestModal"
import "./DestinationSearchInput.css"

const DestinationSearchInput = ({
  label,
  placeHolder,
  isAddGuest,
  isLocation,
  isEmpty,
}) => {
  const [isInputFocusing, setIsInputFocusing] = useState(false)

  const renderLocationModal = (setSearchParameters, searchParameters) => {
    return (
      isInputFocusing && (
        <SearchInputModal
          setSearchValue={setSearchParameters}
          searchParameters={searchParameters}
        />
      )
    )
  }

  const renderAddGuestModal = (setSearchParameters, searchParameters) => {
    return (
      isInputFocusing && (
        <AddGuestModal
          setSearchValue={setSearchParameters}
          searchParameters={searchParameters}
        />
      )
    )
  }

  const handleGuestsChange = (searchParameters, setSearchParameters, value) => {
    setSearchParameters({
      ...searchParameters,
      guests: value,
    })
  }

  return (
    <>
      <div className="destiantion-search-input-root">
        <p className="destination-search-input-label">{label}</p>
        {isLocation && (
          <ResultModalContext.Consumer>
            {({ searchParameters, setSearchParameters }) => (
                <input
                  style={{
                    borderBottom:
                      isEmpty && isEmpty.clicked && isEmpty.status
                        ? "2px solid red"
                        : "none",
                  }}
                  readOnly={isAddGuest && "readonly"}
                  value={searchParameters.location}
                  onChange={({ target }) =>
                    setSearchParameters({
                      ...searchParameters,
                      location: target.value,
                    })
                  }
                  placeholder={placeHolder}
                  onFocus={() => {
                    setIsInputFocusing(true)
                  }}
                  onBlur={() =>
                    isLocation && setTimeout(() => setIsInputFocusing(false), 100)
                  }
                  className="destination-search-input"
                />
            )}
          </ResultModalContext.Consumer>
        )}

        {isAddGuest && (
          <ResultModalContext.Consumer>
            {({ searchParameters, setSearchParameters }) => (
              <input
                style={{
                  borderBottom:
                    isEmpty && isEmpty.clicked && isEmpty.status
                      ? "2px solid red"
                      : "none",
                }}
                readOnly={isAddGuest && "readonly"}
                value={`${searchParameters.guests} guests / ${searchParameters.guestsDetails.rooms} rooms`}
                onChange={({ target }) =>
                  handleGuestsChange(
                    searchParameters,
                    setSearchParameters,
                    target.value
                  )
                }
                placeholder={placeHolder}
                onFocus={() => {
                  setIsInputFocusing(true)
                }}
                onBlur={() =>
                  isLocation && setTimeout(() => setIsInputFocusing(false), 100)
                }
                className="destination-search-input"
              />
            )}
          </ResultModalContext.Consumer>
        )}

        <ResultModalContext.Consumer>
          {({ setSearchParameters, searchParameters }) =>
            isLocation && renderLocationModal(setSearchParameters, searchParameters)
          }
        </ResultModalContext.Consumer>
        <ResultModalContext.Consumer>
          {({ setSearchParameters, searchParameters }) =>
            isAddGuest && renderAddGuestModal(setSearchParameters, searchParameters)
          }
        </ResultModalContext.Consumer>
      </div>
      {isInputFocusing && isAddGuest && <Modal isClick={setIsInputFocusing} />}
    </>
  )
}

export default DestinationSearchInput
