import React from "react"
import Typography from "../../../Typography/Typography"
import "./SearchResultsPackageCard.css"

const SearchResultsPackageCard = ({
  imageURL,
  title,
  price,
  duration_day,
  duration_night,
  description,
}) => {
  return (
    <div className="SearchResultsPackageCard">
      <div className="package-image-container">
        <img src={imageURL} alt={title} className="package-image" />
        <div className="package-details mobile">
          <div className="duration-container">
            <Typography category="label" text="Duration" />
            <Typography
              category="info"
              text={`${duration_day}D/${duration_night}N`}
            />
          </div>
          <div className="price-container">
            <Typography category="label" text="Starting Price" />
            <Typography category="info" text={`₺${price}`} />
          </div>
        </div>
      </div>
      <div className="package-information">
        <Typography title category="search-results-packages-title" text={title} />
        <Typography
          category="search-results-packages-description"
          text={description}
        />
        <div className="package-details">
          <div className="duration-container">
            <Typography category="label" text="Duration" />
            <Typography
              category="info"
              text={`${duration_day}D/${duration_night}N`}
            />
          </div>
          <div className="price-container">
            <Typography category="label" text="Starting Price" />
            <Typography category="info" text={`₺${price}`} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SearchResultsPackageCard
