import React, { useEffect, useState, useRef } from "react"
import axios from "axios"
import PackageCard from "../PackageCard/PackageCard"
import Typography from "../../../Typography/Typography"
import { ReactComponent as RightArrow } from "../../../../../images/svg/ShowMoreButton.svg"
import { ReactComponent as LeftArrow } from "../../../../../images/svg/BackwardArrow.svg"
import { useTranslation } from "react-i18next"
import "./PackagesCarousel.css"

const PackagesCarousel = () => {
  const [packages, setPackages] = useState([])
  const [carouselIsScrolled, setCarouselIsScrolled] = useState(false)
  const [carouselIsAtEnd, setCarouselIsAtEnd] = useState(false)
  const [tabs, setTabs] = useState({
    activeTab: "Solo adventure",
    tabs: [
      "Solo adventure",
      "Family vacation",
      "Destination wedding",
      "Health & wellness",
      "Turkish delight",
      "Workation",
    ],
  })

  const { t } = useTranslation("packages")

  useEffect(() => {
    const getPackages = async () => {
      try {
        const response = await axios.get(
          "https://cms.test.archisacademy.com/hospitality-packages?_sort=name"
        )
        setPackages(response.data)
      } catch (error) {
        console.log(error.message)
      }
    }
    getPackages()
  }, [])

  const scrollRef = useRef(null)

  const handleShowMoreLeftClick = () => {
    scrollRef.current.scrollLeft -= 550
  }

  const handleShowMoreRightClick = () => {
    scrollRef.current.scrollLeft += 550
  }

  const handleCarouselScroll = () => {
    var carouselMaxWidth =
      scrollRef.current.scrollWidth - scrollRef.current.clientWidth

    if (scrollRef.current.scrollLeft < 50) {
      if (carouselIsScrolled) {
        setCarouselIsScrolled(false)
      }
      if (carouselIsAtEnd) {
        setCarouselIsAtEnd(false)
      }
    } else {
      if (!carouselIsScrolled) {
        setCarouselIsScrolled(true)
      }
    }

    if (scrollRef.current.scrollLeft >= carouselMaxWidth - 50) {
      if (!carouselIsAtEnd) {
        setCarouselIsAtEnd(true)
      }
    } else {
      if (carouselIsAtEnd) {
        setCarouselIsAtEnd(false)
      }
    }
  }

  const handleTabChange = (newActiveTab) => {
    setTabs({ ...tabs, activeTab: newActiveTab })
    scrollRef.current.scrollLeft = 0
  }

  return (
    <div className="PackagesCarousel" id="packages">
      <div className="packages-header">
        <Typography
          title
          category="packages-title"
          text={t("packages.title")}
          className="header-title"
        />
        <div className="tabs">
          {tabs.tabs.map((category) => (
            <div onClick={() => handleTabChange(category)} key={category}>
              <Typography
                className={`tab ${tabs.activeTab === category ? "active" : ""}`}
                text={t("packages." + category)}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="carousel" ref={scrollRef} onScroll={handleCarouselScroll}>
        {packages.length !== 0 &&
          packages
            .filter((category) => category.category === tabs.activeTab)
            .map((destination) => (
              <PackageCard
                imageURL={destination?.image.url}
                title={destination.name}
                price={destination.price}
                duration_day={destination.duration_day}
                duration_night={destination.duration_night}
                description={destination.description}
                key={destination.id}
              />
            ))}
      </div>
      {packages.filter((category) => category.category === tabs.activeTab).length !==
        0 && (
        <div className="show-more-button-section">
          <div className="container">
            {carouselIsScrolled && (
              <LeftArrow
                className="show-more-arrow left"
                onClick={handleShowMoreLeftClick}
              />
            )}

            <Typography
              category={`show-more-text ${
                carouselIsAtEnd ? "back-button-only" : ""
              }`}
              text={t("packages.showmore")}
            />
            {!carouselIsAtEnd && (
              <RightArrow
                className="show-more-arrow right"
                onClick={handleShowMoreRightClick}
              />
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default PackagesCarousel
