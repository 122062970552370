import axios from "axios"

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

export function getHotelResults(data) {
  return api.post("/hotels/search", data)
}

export function getAllHotelDetailsById(id, data) {
  return api.post(`/hotels/${id}`, data)
}

export function getRoomPrice(data) {
  return api.post("/hotels/roomprice", data)
}
