import React, { useState, useEffect } from "react"
import faqTR from "../../../../locales/tr/faq.json"
import faqEN from "../../../../locales/en/faq.json"
import RightArrow from "../../../../images/svg/rightArrow.svg"
import "./FaqSection.css"

const FaqSection = () => {
  const [faqData, setFaqData] = useState()
  const [categories, setCategories] = useState({
    categories: [],
    activeCategory: "",
  })
  const [categorizeQuestions, setCategorizeQuestions] = useState({
    categorizeQuestions: [],
    activeQuestion: "",
  })
  const [answer, setAnswer] = useState("")

  useEffect(() => {
    const language = localStorage.getItem("language")
    if (language === "" || language === "tr") {
      setFaqData(faqTR)
    } else {
      setFaqData(faqEN)
    }
  }, [])

  useEffect(() => {
    if (faqData) {
      faqData.map((faq) => {
        setCategories((prevVal) => {
          return { ...prevVal, categories: [...prevVal.categories, faq.name] }
        })
        return faq
      })
    }
  }, [faqData])

  useEffect(() => {
    if (faqData) {
      const foundQuestionCategory = faqData
        .filter((faq) => faq.name === categories.activeCategory)
        .map((foundCategory) => foundCategory.categorizeQuestions)

      if (foundQuestionCategory[0] !== undefined) {
        foundQuestionCategory[0].forEach((foundQuestions) => {
          if (foundQuestions.question === categorizeQuestions.activeQuestion) {
            setAnswer(foundQuestions.answer)
          }
        })
      }
    }
  }, [categorizeQuestions.activeQuestion, categories.activeCategory, faqData])

  const showCategoryQestion = (category) => {
    setAnswer("")
    setCategorizeQuestions({
      categorizeQuestions: [],
      activeQuestion: "",
    })
    setCategories((prevVal) => {
      return { ...prevVal, activeCategory: category }
    })

    setCategorizeQuestions((prevVal) => {
      const questions = faqData
        .filter((faq) => faq.name === category)
        .map((foundCategory) => foundCategory.categorizeQuestions)

      return { ...prevVal, categorizeQuestions: questions }
    })
  }

  const showQuestion = (categorizeQestion) => {
    setCategorizeQuestions((prevVal) => {
      return { ...prevVal, activeQuestion: categorizeQestion }
    })
  }

  return (
    <div className="questions-root-container">
      <div className="faq-text-container faq-categories">
        {categories.categories.length !== 0 &&
          categories.categories.map((category, i) => {
            return (
              <div
                onClick={() => showCategoryQestion(category)}
                key={`${category}-${i + 1}`}
                className={`cat ${
                  categories.activeCategory === category && "active"
                }`}
              >
                {category}
                {categories.activeCategory === category && (
                  <img
                    src={RightArrow}
                    className="category-right-arrow"
                    alt="faq right arrow"
                  />
                )}
              </div>
            )
          })}
      </div>
      <div className="faq-text-container faq-categorize-questions">
        {categorizeQuestions.categorizeQuestions.length !== 0 &&
          categorizeQuestions.categorizeQuestions[0].map((question, i) => {
            return (
              <p
                onClick={() => showQuestion(question.question)}
                key={`${question.question}-${i + 1}`}
                className={`${
                  categorizeQuestions.activeQuestion === question.question &&
                  "active"
                }`}
              >
                {question.question}
                {categorizeQuestions.activeQuestion === question.question && (
                  <img
                    src={RightArrow}
                    className="category-right-arrow"
                    alt="faq right arrow"
                  />
                )}
              </p>
            )
          })}
      </div>
      <div className="faq-text-container faq-answers">
        {answer && <p className="active">{answer}</p>}
      </div>
    </div>
  )
}

export default FaqSection
