import React from "react"
import Typography from "../../../Typography/Typography"
import { Link } from "react-router-dom"
import "./SearchResultsDestinationCard.css"
import parse from "html-react-parser"

const SearchResultsDestinationCard = ({ destination, searchParameters }) => {
  const { id, name, description, price, url } = destination
  const { location, checkInDate, checkOutDate, guestsDetails } = searchParameters
  const imageURL = destination?.image.url

  const hotelURL = `/hotels/${id}/${url}` +
    `?location=${location}` +
    `&checkInDate=${checkInDate}` +
    `&checkOutDate=${checkOutDate}` +
    `&rooms=${guestsDetails.rooms}` +
    `&adultVisitors=${guestsDetails.adults}` +
    `&childrenVisitors=${guestsDetails.children}`

  return (
    <Link to={hotelURL} target={"_blank"}>
      <div className="SearchResultsDestinationCard" >
        <div className="destination-image-container">
          <img src={imageURL} alt={name} className="destination-image" />
        </div>
        <div className="destination-information">
          <Typography title category="search-results-destination-title" text={name} />
          {parse(description)}
          {price && (
            <div className="price-container">
              <Typography category="label" text="Starting Price" />
              <Typography category="info" text={`₺${price}`} />
            </div>
          )}
        </div>
      </div>
    </Link>
  )
}

export default SearchResultsDestinationCard
