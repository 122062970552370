import React, { useState, useEffect } from "react"
import { ReactComponent as FilterIcon } from "../../../images/svg/filterIcon.svg"
import { getHotelResults } from "../../../api"
import axios from "axios"
import InfiniteScroll from "react-infinite-scroll-component"
import Loader from "react-loader-spinner"

import SearchResultsPackageCard from "./components/SearchResultsPackageCard/SearchResultsPackageCard"
import SearchResultsDestinationCard from "./components/SearchResultsDestinationCard/SearchResultsDestinationCard"
import SearchResultsDayTourCard from "./components/SearchResultsDayTourCard/SearchResultsDayTourCard"
import "./SearchResults.css"
import Map from "../../pages/Map"
import Typography from "../Typography/Typography"
import DestinationSearchBar from "../DestinationSearchTopSection/DestinationSearchBar"

const SearchResults = ({
  destinations,
  setDestinations,
  searchParameters,
  isResultsLoading,
  setIsResultsLoading
}) => {
  const [packages, setPackages] = useState([])
  const [dayTours, setDayTours] = useState([])
  const [markers, setMarkers] = useState([])
  const [filtersList, setFiltersList] = useState({
    selectedFilters: "Destination",
    filters: ["Destination", "Packages", "Day Tour", "Services"],
  })

  useEffect(() => {
    const getPackages = async () => {
      try {
        const response = await axios.get(
          "https://cms.test.archisacademy.com/hospitality-packages?_sort=name"
        )
        setPackages(response.data)
      } catch (error) {
        console.log(error.message)
      }
    }

    const getDayTours = async () => {
      try {
        const response = await axios.get(
          "https://cms.test.archisacademy.com/hospitality-day-tours?_sort=name"
        )
        setDayTours(response.data)
      } catch (error) {
        console.log(error.message)
      }
    }

    getPackages()
    getDayTours()
  }, [])

  useEffect(() => {
    const markersData = [...destinations].filter(destination =>
      destination.info && destination.info.latitude && destination.info.longitude
    )
    .map(destination => {
      return {
        id: destination.id,
        latitude: destination.info.latitude,
        longitude: destination.info.longitude,
        price: destination.data?.price,
      }
    })

    setMarkers(markersData)
  }, [destinations])

  const handleFilter = () => {
    setDestinations((prevDestination) => {
      return [...prevDestination].sort((a, b) => (a.price > b.price) ? 1 : -1)
    })
  }

  const loadNext = async () => {
    setIsResultsLoading(true)

    try {
      const res = await getHotelResults({
        location: searchParameters.location,
        checkInDate: searchParameters.checkInDate,
        checkOutDate: searchParameters.checkOutDate,
        adultVisitors: searchParameters.guestsDetails.adults,
        childrenVisitors: [],
        noOfRooms: searchParameters.guestsDetails.rooms,
        init: false,
        hotels: destinations.filter((result) => !result.info)
      })

      setDestinations((prevDestination) => {
        return [...prevDestination].map(destination => {
          const destinationData = res.data.filter(newDestination => newDestination.id === destination.id)

          if(destinationData.length) {
            return {
              ...destinationData[0],
              data: destinationData[0].data,
              info: destinationData[0].info,
              description: destinationData[0].info?.description,
              price: destinationData[0].data?.price,
              image: {
                url: `${process.env.REACT_APP_SETUR_CDN}/image/hotel/large/${destinationData[0].info?.imageUrl}`
              }
            }
          } else return destination
        })
      })
    } catch (error) {
      console.log(error)
    }
    setIsResultsLoading(false)
  }

  const handleFilterChange = (selectedFilter) => {
    setFiltersList({ ...filtersList, selectedFilters: selectedFilter })
  }

  return (
    <div className="search-results">
      <DestinationSearchBar
        setDestinations={setDestinations}
        isResultsLoading={isResultsLoading}
        setIsResultsLoading={setIsResultsLoading}
        modal
      />
      <div className="header-section">
        <div className="filter-section">
          <div className="filter-title-label">
            <FilterIcon className="filter-icon" />
            <Typography className="filter-text" category="label" text="filters" />
          </div>
          <div className="filter-types-container">
            {filtersList.filters.map((filterCategory) => (
              <div
                className={`filter-type-tab ${
                  filtersList.selectedFilters.includes(filterCategory)
                    ? "selected"
                    : ""
                }`}
                onClick={() => handleFilterChange(filterCategory)}
                key={filterCategory}
              >
                {filterCategory}
              </div>
            ))}
            <div className="filter-type-tab selected">Price • ₺500 - ₺1000</div>
            <Typography
              category="label"
              text="clear all filters"
              className="clear-filters"
            />
            <div className="filter-sort-container">
              <div className="filter-type-tab selected filter-sort right" onClick={handleFilter}>
                Price • Low to High
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="results">
        <div id="packages-container" className="packages-container">
          {filtersList.selectedFilters === "Destination"
            ? (
              <InfiniteScroll
                dataLength={destinations.filter(destination => destination.data).length}
                next={loadNext}
                hasMore={destinations.some(destination => !destination.data)}
                loader={
                  <Loader
                    type="ThreeDots"
                    color="#fff"
                    className="three-dots-loader"
                    height={40}
                    width={40}
                  />
                }
                scrollableTarget="packages-container"
              >
                {destinations.filter(destination => destination.info).map((destination, i) => (
                  <SearchResultsDestinationCard
                    destination={destination}
                    searchParameters={searchParameters}
                    key={i}
                  />
                ))}
              </InfiniteScroll>
            ) : null}
          {filtersList.selectedFilters === "Packages"
            ? packages.map((destination) => (
                <SearchResultsPackageCard
                  imageURL={destination?.image.url}
                  title={destination.name}
                  price={destination.price}
                  duration_day={destination.duration_day}
                  duration_night={destination.duration_night}
                  description={destination.description}
                  key={destination.id}
                />
              ))
            : null}
          {filtersList.selectedFilters === "Day Tour"
            ? dayTours.map((destination) => (
                <SearchResultsDayTourCard
                  imageURL={destination?.image.url}
                  title={destination.name}
                  location={destination.location}
                  key={destination.id}
                />
              ))
            : null}
        </div>
        <div className="mapbox-container">
          <Map markers={markers} />
        </div>
      </div>
    </div>
  )
}

export default SearchResults
