import Logo from "../../../images/logo.svg"

const FooterLogo = () => {
  return (
    <div className="footer-logo-container">
      <img
        onClick={() => window.scrollTo({ top: 0, left: 0, behavior: "smooth" })}
        className="footer-logo"
        src={Logo}
        alt=""
      />
    </div>
  )
}

export default FooterLogo
