import React from "react"
import MapPinIcon from "../../../../../images/svg/map-pin.svg"
import places from "./places"
import "./SearchInputModal.css"

const SearchInputModal = ({ setSearchValue, searchParameters }) => {
  return (
    <div className="search-input-modal-root">
      <div className="search-input-modal-title-container">
        <h3>Popular destinations</h3>
      </div>
      <div className="search-recomended-places-list">
        {places
          .filter(function (city) {
            return city
              .toLowerCase()
              .includes(searchParameters.location.toLowerCase())
          })
          .map((city) => (
            <div
              key={city}
              onClick={() => {
                setSearchValue({ ...searchParameters, location: city })
              }}
              className="search-recomended-place"
            >
              <span className="search-map-svg">
                <img src={MapPinIcon} alt="city pin icon" />
              </span>
              <p className="recomended-city-name">{city}</p>
            </div>
          ))}
      </div>
    </div>
  )
}

export default SearchInputModal
