import React, { useEffect, useState } from "react"
import { Row, Col } from "antd"
import { useParams, useLocation } from "react-router-dom"
import { getAllHotelDetailsById } from "../../../api"
import Loader from "react-loader-spinner"
import Gallery from "react-grid-gallery"
import parse from "html-react-parser"
import Map from "../../pages/Map"
import RoomSearchBar from "../../elements/DestinationSearchTopSection/RoomSearchBar"
import Typography from "../Typography/Typography"
import "./HotelDedicated.css"

const HotelDedicated = () => {
  const { id } = useParams()
  const queryString = new URLSearchParams(useLocation().search);
  const location = queryString.get("location")
  const checkInDate = queryString.get("checkInDate")
  const checkOutDate = queryString.get("checkOutDate")
  const adultVisitors = queryString.get("adultVisitors")
  const rooms = queryString.get("rooms")

  const [hotel, setHotel] = useState({})
  const [markers, setMarkers] = useState([])
  const [images, setImages] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getAllHotelDetailsById(id, {
          location,
          checkInDate,
          checkOutDate,
          adultVisitors,
          childrenVisitors: []
        })
        setHotel(res.data)

        if (res.data && res.data.latitude && res.data.longitude) {
          const latitude = +res.data.latitude.replace(/,/g, '.')
          const longitude = +res.data.longitude.replace(/,/g, '.')
          setMarkers([{
            longitude: longitude,
            latitude: latitude,
          }])
        }

        const imageArray = res.data.hotelImages.map(image => {
          return {
            src: `${process.env.REACT_APP_SETUR_CDN}/image/hotel/large/${image.url}`,
            thumbnail: `${process.env.REACT_APP_SETUR_CDN}/image/hotel/large/${image.url}`,
            thumbnailWidth: 320,
            thumbnailHeight: 200,
            caption: "",
          }
        })
        setImages(imageArray)
        setIsLoading(false)
      } catch (error) {
        console.log(error)
      }
    }

    fetchData()
  }, [id, location, checkInDate, checkOutDate, adultVisitors])

  return (
    <div className="hotels-wrapper">
      {isLoading ? (
        <Loader
          type="ThreeDots"
          color="#fff"
          className="three-dots-loader"
          height={40}
          width={40}
        />
      ) : (
        <>
          <div className="images-container">
            <Gallery
              images={images}
              rowHeight={300}
              maxRows={2}
              margin={5}
              backdropClosesModal={true}
            />
          </div>
          <div className="hotel-room-search-container">
            <RoomSearchBar
              id={id}
              checkInDate={checkInDate}
              checkOutDate={checkOutDate}
              adultVisitors={adultVisitors}
              rooms={rooms}
            />
          </div>
          <div className="details-container">
            <div className="hotel-details">
              <Typography title category="dedicated-hotel-title" text={hotel.hotelName} />
              {hotel.details && hotel.details.price && (
                <div className="dedicated-hotel-price-container">
                  <Typography category="label" className="hotel-price-label" text="Starting Price" />
                  <Typography category="info" className="hotel-price-info" text={`₺${hotel.details.price}`} />
                </div>
              )}
            </div>
            <div className="dedicated-hotel-description">
              {hotel.hotelDescription && parse(hotel.hotelDescription)}
            </div>
            {hotel.rooms && hotel.rooms.length > 0 && (
              <div className="dedicated-hotel-rooms">
                {hotel.rooms.filter(room => room.roomImages.length)
                .map((room, index) => (
                  <div className="hotel-rooms-container" key={index}>
                    <div className="room-image-container">
                      <img src={`${process.env.REACT_APP_SETUR_CDN}/image/hotel/thumbnail/${room.roomImages[0].url}`}
                        alt={room.roomName}
                        className="room-image"
                      />
                    </div>
                    <div className="room-details">
                      <Typography title category="hotel-room-title" text={room.roomName} />
                      <Row gutter={[10, 10]}>
                        {room.roomDescription && parse(room.roomDescription)}
                        {room.roomFeatures.map((feature, i) => (
                          <Col span={6} className="room-features-container">
                            <Typography category="info" text={feature.featureName} key={i} className="room-feature" />
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="mapbox-container">
            {markers.length > 0 && <Map markers={markers} isDedicated={true} />}
          </div>
        </>
      )}
    </div>
  )
}

export default HotelDedicated
