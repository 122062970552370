const RangePickerFooter = ({ mydates }) => {
  let start = null;
  let end = null;
  let dayDiffinMs = 0;
  let dayDiff = 0;
  let oneDayinms = 1000 * 60 * 60 * 24

  if (mydates !== null) {
    if (mydates[0] !==null && mydates[1]) {
      start = mydates[0];
      end = mydates[1]
      dayDiffinMs = end.diff(start);
      dayDiff = (dayDiffinMs / oneDayinms);
    };
  }
  return (
    <div className="rangepicker-footer">
      {(mydates !== null) ?
        <div className="rangepicker-footer-withdates">
          <div className="rangepicker-footer-days">{dayDiff + 1} Days / </div>
          <div className="rangepicker-footer-nights">{dayDiff} Nights</div>
        </div>
        : <div className="rangepicker-footer-withoutdates">Please choose your dates</div>
      }
    </div>

  );
}

export default RangePickerFooter;