import React from 'react';
import { useTranslation } from 'react-i18next';
import "./FooterLinks.css";

const FooterLinks = () => {
  const { t } = useTranslation("footer");
  return (
    <div className="footer-links">
      <a className="footer-link-each" href="#destinations">{t("footer.link.dest")}</a>
      <a className="footer-link-each" href="#packages">{t("footer.link.pack")}</a>
      <a className="footer-link-each" href="#daytours">{t("footer.link.daytours")}</a>
      <a className="footer-link-each" href="#services">{t("footer.link.services")}</a>
    </div>
  );
}

export default FooterLinks;