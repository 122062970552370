import React from "react"
import PackagesSection from "../elements/PackagesSection/PackagesSection/PackagesSection"

const Packages = () => {
  return (
    <div className="Packages">
      <PackagesSection />
    </div>
  )
}

export default Packages
