import React from "react"
import "./ServicesSection.css"
import Typography from "../Typography/Typography"
const ServiceCard = ({ title, url }) => {
  return (
    <div className="service-card">
      <img className="service-card-img" src={url} alt={title} />
      <Typography category="service-card-title" text={title} />
    </div>
  )
}

export default ServiceCard
