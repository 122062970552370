import React, { useState, useEffect } from "react"
import axios from "axios"
import DestinationsCard from "./DestinationsCard"
import Typography from "../../Typography/Typography"
import { ReactComponent as ShowMoreBtn } from "../../../../images/downArrow.svg"
import { useTranslation } from "react-i18next"
import "./DestinationsCarousel.css"

const DestinationsCarousel = () => {
  const { t } = useTranslation("destinations")
  const [destinations, setDestinations] = useState([])
  const [showMore, setShowMore] = useState(false)
  const [defaultCard, setDefaultCard] = useState("")
  const [limit, setLimit] = useState(4)


  const handleShowMore = () => {
    if (showMore) {
      setLimit(4)
    }
    setShowMore(!showMore)
  }

  useEffect(() => {
    const getDestinations = async () => {
      try {
        const response = await axios.get(
          "https://cms.test.archisacademy.com/hospitality-destinations?_sort=name"
        )
        setDestinations(response.data)
        setDefaultCard(response.data[0].name)
      } catch (error) {
        console.log(error.message)
      }
    }
    getDestinations()
  }, [])

  return (
    <div className="destinations-carousel-root">
      <Typography
        title
        text={t("destinations.title")}
        category="destinations-header"
      />

      <div className= {`${!showMore ? "destinations-carousel" : "destinations-carousel-showmore"}`}>
          {destinations.map((destination, i) => (
            i<limit && <div key={i} className="destination-cards-container">
            <DestinationsCard
              key={destination.id}
              imageURL={destination?.image.url}
              title={destination.name}
              description={destination.description}
              defaultCard={showMore ? destination.name : defaultCard}
              setDefaultCard={setDefaultCard}
              showMore={showMore}
            />
            </div>
          ))}
      </div>

      {
        <div className="show-more-button-wrapper">
          <div className="show-more-container" onClick={handleShowMore}>
            <Typography 
              className="show-more-text" 
              category="show-more-text" 
              text = {showMore ? t("destinations.showLess") : t("destinations.showMore")} 
            />
            <ShowMoreBtn className={`show-more-button ${showMore ? "rotate-arrow": ''}`}/>
          </div>
        </div>
      }
    </div>
  )
}

export default DestinationsCarousel