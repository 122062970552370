import React from "react"
import FooterLinks from "../FooterSection/FooterLinks"
import FooterContact from "../FooterSection/FooterContact"
import FooterLogo from "../FooterSection/FooterLogo"
import FacebookLogo from "../../../images/facebook.svg"
import InstagramLogo from "../../../images/instagram.svg"
import TwitterLogo from "../../../images/twitter.svg"
import YoutubeLogo from "../../../images/youtube.svg"
import "./FooterSection.css"
import { Row, Col } from "antd"
import { useTranslation } from "react-i18next"

const socialLinks = [
  {
    id: 1,
    url: "https://www.facebook.com",
    imgSrc: FacebookLogo,
  },
  {
    id: 2,
    url: "https://www.instagram.com",
    imgSrc: InstagramLogo,
  },
  {
    id: 3,
    url: "https://www.twitter.com",
    imgSrc: TwitterLogo,
  },
  {
    id: 4,
    url: "https://www.youtube.com",
    imgSrc: YoutubeLogo,
  },
]

const FooterSection = () => {
  const { t } = useTranslation("footer")
  return (
    <div className="footer-container">
      <Row className="footer-section-1">
        <Col xs={24} xl={12}>
          <FooterLogo />
        </Col>
        <Col xs={24} xl={12} className="footer-nav-and-contact-wrapper">
          <FooterLinks />
          <FooterContact />
        </Col>
      </Row>

      <div className="footer-section-2">
        <p className="footer-text">{t("footer.copyright")} © Moringa Tours 2021</p>
        <div className="footer-social-links-wrapper">
          {socialLinks.map((socialLink) => (
            <a
              key={socialLink.id}
              href={socialLink.url}
              className="footer-social-links"
            >
              <img src={socialLink.imgSrc} alt="" />
            </a>
          ))}
        </div>
        <div>
          <p className="footer-text">
            <a href="#terms">{t("footer.term")}</a>
            {" • "}
            <a href="#privacy-policy">{t("footer.privacy")}</a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default FooterSection
