import React from "react"
import Typography from "../../../Typography/Typography"
import "./SearchResultsDayTourCard.css"

const SearchResultsDayTourCard = ({ imageURL, title, location }) => {
  return (
    <div className="SearchResultsDayTourCard">
      <div className="day-tour-image-container">
        <img src={imageURL} alt={title} className="day-tour-image" />
      </div>
      <div className="day-tour-information">
        <Typography title category="search-results-day-tour-title" text={title} />
        <Typography
          title
          category="search-results-day-tour-location"
          text={location}
        />
      </div>
    </div>
  )
}

export default SearchResultsDayTourCard
