import React, { useState } from "react"
import GuestIncrement from "./GuestIncrement/GuestIncrement"
import "./AddGuestModal.css"

const AddGuestModal = ({ setSearchValue, searchParameters }) => {
  const [guestCategories, setGuestCategories] = useState([
    {
      name: "Adults",
      explainedName: "Ages 18 and above",
      guestNumber: searchParameters.guestsDetails.adults,
    },
    {
      name: "Children",
      explainedName: "Ages 4 - 17",
      guestNumber: searchParameters.guestsDetails.children,
    },
  ])


  const handleGuestOperation = (operation, guestName) => {
    if (guestName === "Rooms") {
      if (operation === "sub") {
        setSearchValue((prevVal) => {
          return {
            ...prevVal,
            guestsDetails: {
              ...prevVal.guestsDetails,
              rooms: prevVal.guestsDetails.rooms - 1
            },
          }
        })
      } else {
        setSearchValue((prevVal) => {
          return {
            ...prevVal,
            guestsDetails: {
              ...prevVal.guestsDetails,
              rooms: prevVal.guestsDetails.rooms + 1
            },
          }
        })
      }
    }

    if (operation === "sub") {
      setGuestCategories(
        [...guestCategories].map((guestItems) => {
          if (guestItems.name === guestName) {
            return {
              ...guestItems,
              guestNumber: guestItems.guestNumber - 1,
            }
          } else return guestItems
        })
      )

      guestCategories.map((guestItems) => {
        if (guestItems.name === guestName) {
          setSearchValue((prevVal) => {
            return {
              ...prevVal,
              guests: prevVal.guests - 1,
              guestsDetails: {
                ...prevVal.guestsDetails,
                adults: guestName === "Adults" ? prevVal.guestsDetails.adults - 1 : prevVal.guestsDetails.adults,
                children: guestName === "Children" ? prevVal.guestsDetails.children - 1 : prevVal.guestsDetails.children
              },
            }
          })
        }

        return guestItems
      })
    } else {
      setGuestCategories(
        [...guestCategories].map((guestItems) => {
          if (guestItems.name === guestName) {
            return {
              ...guestItems,
              guestNumber: guestItems.guestNumber + 1,
            }
          } else return guestItems
        })
      )

      guestCategories.map((guestItems) => {
        if (guestItems.name === guestName) {
          setSearchValue((prevVal) => {
            return {
              ...prevVal,
              guests: prevVal.guests + 1,
              guestsDetails: {
                ...prevVal.guestsDetails,
                adults: guestName === "Adults" ? prevVal.guestsDetails.adults + 1 : prevVal.guestsDetails.adults,
                children: guestName === "Children" ? prevVal.guestsDetails.children + 1 : prevVal.guestsDetails.children
              },
            }
          })
        }

        return guestItems
      })
    }
  }

  return (
    <div className="add-guests-modal-root">
      {guestCategories.map((guest) => {
        return (
          <GuestIncrement
            key={guest.name}
            guestName={guest.name}
            guestNameExplained={guest.explainedName}
            guestNumber={guest.guestNumber}
            handleGuestOperation={handleGuestOperation}
          />
        )
      })}
      <GuestIncrement
        guestName={"Rooms"}
        guestNameExplained={""}
        guestNumber={searchParameters.guestsDetails.rooms}
        handleGuestOperation={handleGuestOperation}
      />
    </div>
  )
}

export default AddGuestModal
