import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import ReactPlayer from "react-player"
import PlayButton from "../../images/svg/play.svg"
import "../elements/HeroSection/Hero.css"

const Hero = () => {
  const { t } = useTranslation("hero")
  const [openModal, setOpenModal] = useState(false)

  const playFilm = () => {
    setOpenModal(!openModal)
  }

  return (
    <>
      <div className="hero">
        <div className="hero-video">
          <div className="hero-video-container">
            <ReactPlayer
              className="vimeo-player"
              playing={true}
              loop={true}
              width="1920px"
              height="1080px"
              url="https://vimeo.com/568067570/624c71fbc3"
            />
            <div className="hero-title">
              <h2 className="hero-title-header hero-title-firstline">
                {t("hero.title.line1")}
              </h2>
              <h2 className="hero-title-header hero-title-secondline">
                {t("hero.title.line2")}
              </h2>
            </div>
            <div onClick={() => playFilm()} className="play-button-container">
              <img
                style={{ cursor: "pointer" }}
                src={PlayButton}
                alt="hero video play button"
              />
              <p className="play-film-text">{t("play.film")}</p>
            </div>
          </div>
        </div>
        {openModal && (
          <div className="hero-section-video-modal">
            <div
              onClick={() => setOpenModal(!openModal)}
              className="close-modal-container"
            >
              X
            </div>
            <div className="hero-section-modal-video-container">
              <ReactPlayer
                playing={true}
                loop={true}
                controls={true}
                width="100%"
                height="1000px"
                url="https://vimeo.com/568067570/624c71fbc3"
              />
            </div>
          </div>
        )}
      </div>
    </>
  )
}
export default Hero
