import React from 'react';
import { useTranslation } from 'react-i18next';
import "./FooterContact.css"

const FooterContact = () => {
  const { t } = useTranslation("footer");
  return (
    <div className="footer-contact">
      <div className="email-contact">
        <p className="email-text">{t("footer.contactus")}</p>
        <p className="email-address">info@setur.com.tr</p>
      </div>

      <div className="mobile-contact">
        <p className="mobile-contact-text">{t("footer.urgent")}</p>
        <p className="mobile-contact-number">(0222) 202 00 85</p>
      </div>
    </div>
  );
}

export default FooterContact;