import React from "react"
import Header from "./Header"
import HotelDedicated from "../elements/HotelDedicated/HotelDedicated"
import Footer from "./Footer"

const Hotels = () => {
  return (
    <>
      <Header isSearchHidden />

      <div className="main-container">
        <HotelDedicated />
      </div>

      <Footer />
    </>
  )
}

export default Hotels
