import React, { useState } from "react"
import { ResultModalContext } from "../../context/ResultModal"
import Header from "./Header"
import Hero from "./Hero"
import Destinations from "./Destinations"
import Services from "./Services"
import Packages from "./Packages"
import DayTours from "./DayTours"
import Map from "./Map"
import Footer from "./Footer"
import SearchResults from "../elements/SearchResults/SearchResults"
import DestinationSearchTopSection from "../elements/DestinationSearchTopSection/DestinationSearchBar"

const Home = ({ isResultModalOpen, setIsResultModalOpen }) => {
  const [isResultsLoading, setIsResultsLoading] = useState(false)
  const [searchParameters, setSearchParameters] = useState({
    location: "",
    checkInDate: "",
    checkOutDate: "",
    guests: 0,
    guestsDetails: { adults: 0, children: 0, rooms: 0 },
    init: false,
  })
  const [destinations, setDestinations] = useState([])

  return (
    <ResultModalContext.Provider
      value={{
        isResultModalOpen,
        setIsResultModalOpen,
        isResultsLoading,
        setIsResultsLoading,
        searchParameters,
        setSearchParameters,
        destinations,
        setDestinations,
      }}
    >
      <Header />

      {isResultModalOpen ? (
        <>
          <SearchResults
            destinations={destinations}
            setDestinations={setDestinations}
            isResultsLoading={isResultsLoading}
            setIsResultsLoading={setIsResultsLoading}
            searchParameters={searchParameters}
            setSearchParameters={setSearchParameters}
          />
        </>
      ) : (
        <>
          <DestinationSearchTopSection
            setDestinations={setDestinations}
            isResultsLoading={isResultsLoading}
            setIsResultsLoading={setIsResultsLoading}
          />
        </>
      )}
      <>
        <Hero />
        <Destinations />
        <Packages />
        <DayTours />
        <Services />
        <Map />
      </>

      <Footer />
    </ResultModalContext.Provider>
  )
}

export default Home
